<template>

  <div>

    <contact-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :company-options="companyOptions"
      @get-contacts="getContacts"
    />

    <contact-edit
      :is-edit-user-sidebar-active.sync="isEditUserSidebarActive"
      :contact-data="contactData"
      :company-options="companyOptions"
      @get-contacts="getContacts"
    />

<!--     <contact-filters
      :user-filter.sync="userFilter"
      :user-options="userOptions"
      :company-filter.sync="companyFilter"
      :company-options="companyOptions"
    /> -->

    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <b-row>

          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>展示</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>条数据</label>
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="搜索"
                v-b-tooltip.hover.bottom="`Email`"

              />
<!--               <b-button
                variant="primary"
                @click="isAddNewUserSidebarActive = true;;"
              >
                <span class="text-nowrap">添加联系人</span>
              </b-button> -->
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative text-nowrap"
        :items="filterContacts"
        :per-page="perPage"
        :current-page="currentPage"
        responsive
        :fields="fields"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <template #cell(gender)="data">
            {{ mapGender(data.item.gender) }}
        </template>

        <template #cell(education)="data">
            {{ mapEdu(data.item.education) }}
        </template>

        <template #cell(job)="data">
            {{ mapJob(data.item.job) }}
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'vendors-contacts-view', params: { id: data.item.contact_id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">查看详情</span>
            </b-dropdown-item>

            <b-dropdown-item @click="isEditUserSidebarActive = true; selectedContact = data.item.contact_id">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>

            <b-dropdown-item
            @click = "deleteContact(data.item.contact_id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">删除</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">正在展示第 {{ dataMeta.from }} 到第 {{ dataMeta.to }} 条数据 （共 {{ totalContacts }} 条数据</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalContacts"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ContactFilters from './ContactFilters.vue'
import ContactAddNew from './ContactAddNew.vue'
import ContactEdit from './ContactEdit.vue'

export default {
  components: {
    ContactFilters,
    ContactAddNew,
    ContactEdit,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data(){
    return {
      isSortDirDesc: false,
      perPageOptions: [10, 25, 50, 100],
      sortBy :'id',
      isAddNewUserSidebarActive: false,
      isEditUserSidebarActive: false,
      selectedContact: "",
      perPage:10,
      currentPage:1,
      contacts: [],
      companies: [],
      searchQuery: "",
      userOptions: [],
      companyOptions: [],
      userFilter: "",
      companyFilter:"",
      fields:[
        {key:"user_email", label:"Email"},
        {key:"name", label:"姓名"},
        {key:"gender", label:"性别"},
        {key:"age", label:"年龄"},
        {key:"education", label:"教育水平"},
        {key:"job", label:"职业"},
        {key:"time_cn", label:"注册时间"},
        //{key:"actions", label:"操作"},
      ],
    }
  },
  computed:{
    dataMeta: function(){
      return {
        from: (this.currentPage - 1) * this.perPage + 1,
        to: Math.min(this.currentPage * this.perPage, this.totalContacts),
      }
    },
    contactData: function(){
      return this.contacts.filter(item => item.contact_id == this.selectedContact)[0]
    },
    totalContacts: function(){
      return this.filterContacts.length
    },
    filterContacts: {
      get(){
        return this.contacts.filter((contact)=>{
            return contact.user_email.includes(this.searchQuery)
        })
      },
    },
  },
  methods:{
    getUsers(){
      this.$axios.get("/manage/users/get_users").then((res)=>{
        if (res.data.status == "ok"){
          res.data.data.users.forEach((user)=>{
            this.userOptions.push({label:user.user_name,value:user.user_id})
          })
        }
      })
    },
    getContacts(){
      this.$axios.get("vendors/individuals/get_individuals").then((res)=>{
        if (res.data.status == "ok"){
          this.contacts = res.data.data.individuals;
        }
      })
    },
    getCompanies: function(){
      this.$axios.get("vendors/companies/get_companies").then((res)=>{
        if (res.data.status == "ok"){
          res.data.data.companies.forEach((company)=>{
            this.companyOptions.push({label:company.name,value:company.company_id})
          })
        }
      })
    },
    deleteContact: function(contact_id){
      this.$swal({
        title: '删除联系人',
        text: "删除后无法撤销，你确定要删除该联系人吗？",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '是的，删除！',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$axios.get("vendors/contacts/del_contact/" + contact_id).then((res)=>{
        if (res.data.status == "ok"){
          this.getContacts();
        }
      })        
    }
      })


    },
    mapGender(gender){
      if (gender == "male") return "男"
      if (gender == "female") return "女"
    },
    mapEdu(edu){
      if (edu == "primary") { 
        return "小学"
      }else if (edu == "junior") {
        return "初中"
      }else if (edu == "highschool") {
        return "高中"
      }else if (edu == "college") {
        return "大学专科"
      }else if (edu == "university") {
        return "大学本科"
      }else if (edu == "master") {
        return "硕士"
      }else if (edu == "doctor") {
        return "博士"
      }else if(edu == "other"){
        return "其他"
      }else{  
        return edu
      }
    },
    mapJob(job){
      if (job == "student"){
        return "学生"
      }else if(job == "stuff"){
        return "企业员工"
      }else if (job == "self-employed"){
        return "自由职业者"
      }else if (job == "other"){
        return "其他"
      }
    }
  },
  created(){
    this.getContacts()
    this.getUsers()
    this.getCompanies()
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
